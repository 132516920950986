import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import OurProducts from "../components/products/our-products";
import TopRecipes from "../components/recipes/top-recipes";
import BackgroundImage from "../images/fundo-receitas.jpg";

const ReceitasPage = ({ data }) => {
  const { allMarkdownRemark } = data;

  return (
    <Layout>
      <SEO title="Receitas" keywords={["receitas"]} image={BackgroundImage} />

      <HeaderImage image={BackgroundImage}>
        <MainLayout>
          <Header
            title="Prontos a ajudar…"
            subtitle="À procura de novas receitas?"
          />
        </MainLayout>
      </HeaderImage>
      <TopRecipes recipes={allMarkdownRemark.edges} />

      <OurProducts />
    </Layout>
  );
};

export default ReceitasPage;

export const IndexQuery = graphql`
  query IndexQuery {
    file(relativePath: { eq: "fundo-receitas.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { layout: { eq: "blog" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY", locale: "pt")
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 75)
              }
            }
          }
        }
      }
    }
  }
`;
