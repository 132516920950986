import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const PreviewRecipeContainer = styled(Link)`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
`;

const PreviewRecipeImg = styled(GatsbyImage)`
  width: 100%;
  object-position: 50% 50%;
  object-fit: cover;
  padding: 5px;
  background-color: var(--color3);
  box-sizing: border-box;
  height: 220px;
  border-radius: 10px;
  transition: all 0.2s;

  &:hover {
    opacity: 0.5;
  }

  @media screen and (min-width: 1024px) {
    width: 270px;
    height: 235px;
  }
`;

const PreviewRecipeTitle = styled.div`
  font-size: 26px;
  letter-spacing: 0.71px;
  font-weight: bold;
  color: var(--color3);
  margin-top: 10px;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 1024px) {
    width: 270px;
    height: 235px;
  }
`;

const PreviewRecipe = ({ recipe }) => (
    <PreviewRecipeContainer to={recipe.node.fields.slug}>
      <PreviewRecipeImg
        image={recipe.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        alt={recipe.node.frontmatter.title}
      />
      <PreviewRecipeTitle>{recipe.node.frontmatter.title}</PreviewRecipeTitle>
    </PreviewRecipeContainer>
  );

export default PreviewRecipe;
